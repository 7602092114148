import Vue from 'vue'
import axios from 'axios'
import router from '../router'
import moment from 'moment'
import { convertToArray } from 'v-tooltip/src/utils'
import { eventBus } from '@/services/event-bus'
const CancelToken = axios.CancelToken
let source

const actions = {
  // main fetch function
  async fetchJSON (context, link) {
    context.commit('setLoading')
    let reset = false
    if(Array.isArray(link) && link.length > 1){
      reset = link[1]
      link = link[0]
    }
    //empty mainJSON first
    // context.commit('setMainJSON', {})
    let api = Vue.prototype.$clientConfig.VUE_APP_API_URL + link
    console.log('==== Fetching ' + api + ' JSON =====')

    //SET header
    console.log(localStorage.getItem('access_token'))
    if (localStorage.getItem('access_token') != null) {
      axios.defaults.headers.common['Auth-Token'] = localStorage.getItem(
        'access_token'
      )
    }

    if(typeof resetFilters != "undefined") {
      console.log("reset?!", resetFilters)
    }
    return await axios
      .get(api)
      .then(response => {
        if(reset) {
          console.log("reset?!")
          context.commit('removeAllFilterData')
          context.commit('removeSelectedRows')
          context.commit('removeSortData')
        }
        console.log('fetchJSON success response', response)
        let page = response ? response.data.page : {}
        if (response?.data?.download) {
          context.dispatch('download', response.data)
        } else if (response && response?.data?.redirect) {
          //redirect data ( might have to dispatch redirect for external urls ))
          router.push(response.data.redirect)
        } else if (page.type) {
          let loadResponse = true
          let lastRoutes = context.state.lastRoutes

          if (lastRoutes.length > 1) {
            console.log(lastRoutes[lastRoutes.length - 1], link)
            if (lastRoutes[lastRoutes.length - 1] !== link) {
              console.log('simultaneous pages loaded, discarded route: ' + link)
              loadResponse = false
            }
          }

          if (loadResponse) {
            // JSON
            let data = JSON.stringify(page)
            context.commit('setMainJSON', JSON.parse(data))
            let t = response.data.translations
            let l = 'en'
            context.dispatch('updatecurrentLocale', { l, t })
            context.commit('removeLastRoutes', [])
            eventBus.$emit('update-browse-filters')
          }
        } else {
          context.commit('setLoadingOrError', 'Unknown JSON format')
          console.log(response)
        }
        if (response && response.data.toast) {
          context.dispatch('setToast', response.data.toast)
        }
        return Promise.resolve(response)
      })
      .catch(error => {
        console.log(error)
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('removeLastRoutes', link)
        context.commit('stopLoading')
      })
  },
  async fetchJSONLocal (context, link) {
    // fetch Json and return response to component for local use
    context.commit('setLoading')
    let api = `${Vue.prototype.$clientConfig.VUE_APP_API_URL}/${link}`
    console.log('==== Fetching ' + api + ' JSON Local =====')

    return await axios
      .get(api)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        console.log(error)
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('stopLoading')
      })
  },
  login (context, credentials) {
    console.log('==== Logging in =====')
    context.commit('setLoading')
    let api = Vue.prototype.$clientConfig.VUE_APP_API_URL + '/login'

    var bodyFormData = new FormData()
    for (let prop in credentials) {
      bodyFormData.set(prop, credentials[prop])
    }

    console.log('POST: ' + api)

    return axios({
      method: 'POST',
      url: api,
      withCredentials: true,
      crossdomain: true,
      data: bodyFormData
    })
        .then(response => {
          console.log(response)
          let page = response.data
          if (page && page.error) {
            return page
          } else if (page) {
            if (page.sessionId) {
              axios.defaults.headers.common['Auth-Token'] = page.sessionId
              context.dispatch('saveLogin', page.sessionId)
              return page
            }
            if (page.redirect) {
              if (page.redirectImmediately) {
                router.push({path: page.redirect})
              } else {
                let action = {
                  method: 'GET',
                  url: response.data.redirect
                }
                context.dispatch('redirect', action)
              }
              return page
            }
            if(page?.multifactor){
              return page
            }
          }
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          context.commit('stopLoading')
        })
  },
  saveLogin (context, token) {
    localStorage.setItem('access_token', token)
    context.commit('setToken', token)
  },
  destroyToken (context) {
    localStorage.removeItem('access_token')
    context.commit('destroyToken')
    delete axios.defaults.headers.common['Auth-Token']
  },
  async submit (context, content) {
    console.log('==== Submitting =====')
    context.commit('setLoading')
    let data = content.formData
    let action = content.action
    let api = Vue.prototype.$clientConfig.VUE_APP_API_URL + action.url
    let closeOnSave = content.closeOnSave
    var bodyFormData = new FormData()
    let obj = content.obj

    if (action.url && action.url.includes('export')) {
      // ===========  exports =============
      console.log(content)
      let payload = context.getters.getFilterSortPageData(data.browseKey)
      console.log('POST: ' + api, {api, payload})

      context.commit('setDownloading', '======== Generating export =========')
      return context.dispatch('export', {api, payload})
    } else {
      // ===========  submit =============
      for (const field of Object.keys(data)) {
        bodyFormData.set(field, data[field])
      }
      if (content.tableInfo) {
        bodyFormData.set('tableInfo', JSON.stringify(content.tableInfo))
      }

      if (obj.attributes.multiSelectOnly || obj.attributes.selectionOnly || obj.attributes.singleSelectionOnly || obj.attributes.includeSelectedRows) {
        let selectedRows = context.getters.getBrowseSettingByKey(obj.attributes.target).selectedRows
        for (const field in selectedRows) {
          bodyFormData.set('selectedRows', JSON.stringify(selectedRows))
        }
      }

      console.log('POST: ' + api, bodyFormData)
      let responseType = 'json'
      if (action?.buttonAttr?.responseType === 'file') responseType = 'arraybuffer'

      return await axios({
        method: action.method,
        url: api,
        withCredentials: true,
        crossdomain: true,
        data: bodyFormData,
        responseType: responseType
      })
          .then(response => {
            console.log('submit response', response)

            // new popup in response
            let newPopup = response.data.popup ? response.data.popup : null
            if (newPopup) {
              let payloadPopup = {
                content: newPopup,
                isOpen: true,
                key: newPopup.key
              }
              context.commit('addPopup', payloadPopup)
            }

            // page in response
            let page = response.data.page ? response.data.page : response.data
            if (response.data.sessionId) {
              context.dispatch('saveLogin', page.sessionId)
              return page
            } else if (response.data.errorMessage) {
              return page
            } else if (page.redirect) {
              if (page.redirectImmediately) {
                router.push({path: page.redirect})
              } else {
                context.dispatch('redirect', {url: page.redirect, method: 'GET'})
              }
            } else if (page && page.key === 'password-reset-confirmation-group') {
              context.commit('setAuthJSON', response.data.page)
            } else if (page && Array.isArray(page)) {
              for (let item of page) {
                if (item.type) {
                  context.dispatch('processResponse', {
                    response: item,
                    obj: obj,
                    key: item.key
                  })
                }
              }
              return page
            } else if (page && page.type) {
              context.dispatch('processResponse', {
                response: page,
                obj: obj,
                key: page.key
              })
              return page
            } else if (response.data.download) {
              context.dispatch('download', response.data)
            } else if (response.data.redirect) {
              if (page.redirectImmediately) {
                router.push({ path: page.redirect })
              } else {
                let action = {
                  method: 'GET',
                  url: response.data.redirect
                }
                context.dispatch('redirect', action)
              }
            } else if (response.headers['content-type'] === 'application/pdf') {
              let data = {
                result: {
                  name: response.headers['content-disposition'].split('filename="')[1].split('"')[0].trim("_"),
                  blobType: 'application/pdf',
                  newTab: action.buttonAttr.newTab === 'true' || action.buttonAttr.newTab === true
                },
                response: response
              }
              context.dispatch('forceFileDownload', data)
            }

            if (!response?.data?.formError) {
              if (closeOnSave) {
                context.commit('closePopupOnSave', content.actionKey)
              }

              if (response && response.data.toast) {
                context.dispatch('setToast', response.data.toast)
              }
            }

            //Replace component(s)
            if(response.data && response.data.componentReplace){
              let componentsReplace = response.data.componentReplace
              for (let componentKey in componentsReplace){
                let component = componentsReplace[componentKey]
                for (let type in component) {
                  let payload = {
                    target: componentKey,
                    content: component[type],
                    popup: context.getters.isObjInsidePopup(componentKey),
                    type: type
                  }
                  console.log('Replace Component via response:', payload)
                  context.commit('replaceTarget', payload)
                }
              }
            }

            return Promise.resolve(response)
          })
          .catch(error => {
            console.log(error)
            context.commit('setDownloading', '')
            return Promise.reject(error)
          })
          .finally(() => {
            context.commit('stopLoading')
          })
    }
  },
  redirect (context, action) {
    if (action && action.url && (action.url.indexOf('http://') === 0 || action.url.indexOf('https://') === 0)) {
      window.location.href = action.url
    }
    let api = Vue.prototype.$clientConfig.VUE_APP_API_URL + action.url
    console.log('==== Redirecting to ' + api + ' ===== ' + action.method)
    context.commit('setLoading')

    if (action.method === 'GET' || action.method === 'get') {
      return axios
          .get(api)
          .then(response => {
            let data = response.data
            console.log(api, data)
            if (data.redirect) {
              if (data.redirect.indexOf('http://') === 0 || data.redirect.indexOf('https://') === 0) {
                window.location.href = data.redirect
              } else {
                router.push({path: data.redirect})
              }
            } else if (data.page) {
              context.commit('setMainJSON', data.page)
            } else if (data?.download) {
              context.dispatch('download', data)
            }
            return data
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            context.commit('stopLoading')
          })
    } else if (action.method === 'POST' || action.method === 'post') {
      if (localStorage.getItem('access_token') != null && action.url == '/logout') {
        axios.defaults.headers.common['Auth-Token'] = localStorage.getItem(
          'access_token'
        )
      }
    return axios
        .post(api)
        .then(response => {
          let page = response.data
          console.log(response)
          return page
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          context.commit('stopLoading')
        })
    }
  },
  retrieveContent (context, data) {
    context.commit('setLoading')
    let action =  data.action
    let payload = data.formdata
    let api = Vue.prototype.$clientConfig.VUE_APP_API_URL + action.url
    console.log('===== retrieving content ======')
    let sessionId = context.getters.getToken
    var bodyFormData = new FormData()


    if (payload) {
      for (const field of Object.keys(payload)) {
        bodyFormData.set(field, payload[field])
      }
    }
    if (data.tableInfo) {
      bodyFormData.set('tableInfo', JSON.stringify(data.tableInfo))
    }

    console.log(api, sessionId)
    return axios({
      method: action.method,
      url: api,
      withCredentials: true,
      crossdomain: true,
      data: bodyFormData
    })
      .then(response => {
        console.log(response)
        let page = response.data && response.data.page ? response.data.page : response.data
        if (page && page.redirect) {
          if (page.redirectImmediately) {
            router.push({ path: page.redirect })
          } else {
            //redirect data
            let action = {
              method: 'GET',
              url: page.redirect
            }
            context.dispatch('redirect', action)
          }
        } else if (response?.data?.download) {
          context.dispatch('download', response.data)
        }
        return page
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        context.commit('stopLoading')
      })
  },
  gotoPage (context, data) {
    context.commit('setLoading')
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + data.url

    let payload = context.getters.getFilterSortPageData(data.browseKey)
    console.log('==== GoTo: ' + url + ' =====', data)

    return axios.post(url, payload)
        .then(response => {
          console.log(response)
          context.dispatch('processResponse', {
            response: response.data.page,
            obj: null,
            key: data.browseKey
          })
          return response
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          context.commit('stopLoading')
        })
  },
  export (context, data) {
    context.commit('setLoading')
    let action = data.action
    if (action && action.method == 'GET') {
      let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + action.url
      return axios
        .get(url)
        .then(response => {
          console.log(response.data)
          if (response.data.download) {
            context.dispatch('download', response.data)
          } else {
            context.dispatch('processResponse', {
              response: response.data.page,
              obj: data.pressedBtn,
              key: data.browseKey
            })
          }
          return response.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          context.commit('stopLoading')
        })
    } else {
      let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + data.url
      let filter = context.getters.getFilterSortPageData(data.browseKey)
      let formData = {
        form: data.payload.formData,
        filter: filter
      }
      console.log(url, formData)
      return axios
          .post(url, formData)
          .then(response => {
            console.log(response.data)
            if (response.data.download) {
              context.dispatch('download', response.data)
            } else {
              context.dispatch('processResponse', {
                response: response.data.page,
                obj: data.pressedBtn,
                key: data.browseKey
              })
            }

            return response.data
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            context.commit('stopLoading')
          })
    }
  },
  download (context, data) {
    context.commit('setLoading')
    let pat = /^https?:\/\//i;

    let url = ''

    if (pat.test(data.url))    {
      url = data.url
    } else {
      url = Vue.prototype.$clientConfig.VUE_APP_API_URL + data.url
    }

    console.log('==== Download: ' + url + ' =====')
    axios({
      method: 'post',
      url: url,
      responseType: 'arraybuffer'
    }).then(response => {
      console.log(response)
      let payload = {
        response: response,
        result: data
      }
      context.dispatch('forceFileDownload', payload)
    }).catch(error => {
      context.commit('setDownloading', '')
      console.log(error)
    }).finally(() => {
      context.commit('stopLoading')
    })
  },
  forceFileDownload (context, data) {
    context.commit('setDownloading', '')
    let response = data.response
    let url = ''
    if (data.result.blobType) url = window.URL.createObjectURL(new Blob([response.data], { type: data.result.blobType }))
    else url = window.URL.createObjectURL(new Blob([response.data]))

    const link = document.createElement('a')
    link.href = url
    let name = `${data.result.name}`
    if (data.result.extension) name = name + data.result.extension
    if (data.result.newTab) {
      link.setAttribute('target', '_blank')
    } else {
      link.setAttribute('download', name)
    }
    link.click()
    setTimeout(function(){
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
    }, 100);
  },
  getTableSettings (context) {
    console.log('==== GetTableSettings =====')
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + '/tablesettings'

    return axios
        .post(url)
        .then(response => {
          let payload = {
            type: response.data.type,
            headers: [
              {
                // name: "",
                // order: 0,
                // checkbox: true
              }
            ],
            checkBoxHeaders: [],
            viewType: response.data.type
          }
          commit('setTableSettings', payload)
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
  },
  saveTableSettings (context, data) {
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + `/tablesettings/save/${data.target}/${data.group}`
    console.log('==== Saving tableSettings ====', data.headers)
    let payload = {
      headers: data.headers
    }
    axios
        .post(url, payload)
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
  },
  sortAndFilter (context, data) {
    console.log('==== Filter && Sort Table =====')
    context.commit('setLoading')
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + data.url
    let payload = context.getters.getFilterSortPageData(data.browseKey)
    let btn = data.pressedBtn

    console.log(url)
    console.log(payload, data)

    return axios
        .post(url, payload)
        .then(response => {
          console.log(response.data)
          if (response.data.download) {
            context.dispatch('download', response.data)
          } else {
            context.dispatch('processResponse', {
              response: response.data.page,
              obj: btn,
              key: data.browseKey
            })
          }
          context.commit('setActiveFilterData', data.browseKey)
          return response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          context.commit('stopLoading')
        })
  },
  updatecurrentLocale ({commit}, {instance, locale}) {

    // instance.$nextTick(() => {
    //   commit('update', locale)
    // })

    // commit('update', locale)
  },
  updateTranslations ({commit}, payload) {
    commit('updateTranslations', payload)
  },
  changeLanguage ({context}, language) {
    console.log('==== change language =====')
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + '/language/' + language
    return axios
        .get(url)
        .then(response => {
          return response.data
        })
        .catch(error => {
          console.log(error)
        })
  },
  processResponse (context, data) {
    let page = data.response
    let obj = data.obj
    let key = data.key

    let payloadResponse = {
      page: page,
      popup: context.getters.isObjInsidePopup(key)
    }
    if (page && (page.type == 'LhRoot' || page.type == 'lh-root')) {
      context.commit('setMainJSON', page)
    } else {
      context.commit('replaceTarget', payloadResponse)
    }

    if (obj && obj.attributes && obj.attributes.target) {
      let deselect = {
        key: obj.attributes.target,
        payload: {
          selectedRows: [],
          selectionLength: 0
        }
      }
      context.commit('setBrowseSettingByKey', deselect)
    }

    if (obj && obj.attributes && obj.attributes.closeOnSave && !page?.attributes?.multifactor) {
      context.commit('closePopupOnSave', obj.key)
    }
  },
  submitFile ({commit}, payload) {
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + payload.url
    let file = payload.file
    let formData = new FormData()
    formData.append('file', file)

    axios
        .post(url, formData)
        .then(response => {
          let page = response.data.page
          if (response.data.redirect) {

            if (response.data.redirectImmediately) {
              router.push({ path: response.data.redirect })
            } else {
              //redirect data
              let action = {
                method: 'GET',
                url: response.data.redirect
              }
              context.dispatch('redirect', action)
            }
          } else if (page.type) {
            // JSON
            let data = JSON.stringify(page)
            context.commit('setMainJSON', JSON.parse(data))
            let t = response.data.translations
            let l = 'en'
            context.dispatch('updatecurrentLocale', { l, t })
          } else if (!page.type) {
            // html
            context.commit('setHtmlPage', page)
          } else {
            context.commit('setLoadingOrError', 'Unknown JSON format')
            console.log(response)
          }
        })
        .catch(function (err) {
          console.log(err)
        })
  },
  getAutoSuggestItems (context, data) {
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + data.url
    if (source && source.cancel()) source.cancel('autosuggest request cancelled due to new request')
    source = axios.CancelToken.source()

    return axios
        .post(url, data.payload, { cancelToken: source.token })
        .then(result => {
          return result
        })
        .catch(function (err) {
          console.log(err)
        })
  },
  async postLocal (context, data) {
    context.commit('setLoading')

    let url = `${Vue.prototype.$clientConfig.VUE_APP_API_URL}/${data.url}`
    let payload = data.payload

    console.log('==== Posting ' + url + ' JSON Local =====')
    console.log(payload)

    return await axios
      .post(url, payload)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        console.log(error)
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('stopLoading')
      })
  },
  async submitTranslation (context, payload) {
    context.commit('setLoading')
    console.log('==== Submitting Translation =====', payload)
    let url = Vue.prototype.$clientConfig.VUE_APP_API_URL + `/translations/${payload.add ? 'add' : 'update'}/single`

    return await axios
      .post(url, payload)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(function (err) {
        return Promise.reject(err)
      })
      .finally(() => {
        context.commit('stopLoading')
      })
  },
  async getTranslations (context, link) {
    context.commit('setLoading')
    let api = Vue.prototype.$clientConfig.VUE_APP_API_URL + link
    console.log('==== Fetching Translations for ' + api + ' =====')

    //SET header
    if (localStorage.getItem('access_token') != null) {
      axios.defaults.headers.common['Auth-Token'] = localStorage.getItem(
        'access_token'
      )
    }
    return await axios
      .get(api)
      .then(response => {
        console.log('getTranslations success response', response)
        // Translations are handled by the axios interceptor in http/api.js, we only need the translations so we do nothing else with the response.
        return Promise.resolve(response)
      })
      .catch(error => {
        console.log(error)
        return Promise.reject(error)
      })
      .finally(() => {
        context.commit('stopLoading')
      })
  },
  reloadPage (context, route) {
    context.dispatch('fetchJSON', [route, true])
  },
  // ==============
  closeWindow (context, payload) {
    context.commit('setClose', payload)
  },
  replaceElement (context, payload) {
    context.commit('setReplaceElement', payload)
  },
  setBrowseSettingByKey (context, value) {
    context.commit('setBrowseSettingByKey', value)
  },
  closeAllPopup ({commit}) {
    commit('closeAllPopup')
  },
  setToast (context, toast) {
    eventBus.$emit('show-toast', toast)
  }
}

export default actions
