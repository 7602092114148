<template>
  <div class="mainPage" :class="[checkClasses, {'sidebar-open': !sidebarCollapsed, 'external': isExternalPage}]" id="mainPage" :style="checkStyles">
    <div class="scrollable">
      <component v-for="child in obj.children" :is="child.type" :key="child.key" :obj="child"></component>
    </div>

    <div class="versionNr">
      &copy; Lighthouse v {{ backendVersion }} {{ version }}
      <template v-if="translationEditMode">
        <router-link
            :to="{ name: 'translations', params: { from: $route.path }}"
            key="translations-button"
        >Edit translations</router-link>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'MainPage',
    components: {},
    props: {
      obj: {
        type: Object
      }
    },
    computed: {
      ...mapState({
        version: 'version',
        backendVersion: 'backendVersion',
        sidebarCollapsed: 'sidebarCollapse',
        translationEditMode: 'translationEditMode'
      }),
      isExternalPage() {
        return this.obj?.attributes?.externalPage ? true : false
      }
    },
    methods: {},
    mounted() {
      if(this.isExternalPage){
        let version = document.getElementsByClassName("versionNr")
        let basePage = document.getElementsByClassName("basePage")
        if(version && basePage){
          version = version[0]
          basePage = basePage[0]
          //Relocate
          basePage.appendChild(version)
          //Style
          version.style.top = 'auto'
          version.style.left = 'auto'
          version.style.bottom = '18px'
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .mainPage {
    position: relative;
    padding: 0 0 1rem 0;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;

    @include media-breakpoint-up(sm) {
      padding: 1rem 0.2rem;
    }
    @include media-breakpoint-up(md) {
      padding: 2rem 1rem;
      max-height: calc(100 * var(--vh-unit));
      overflow: auto;
    }
    @include media-breakpoint-up(lg) {
      padding: 2rem;
      &.sidebar-open {
        //position: fixed;
      }
    }

    .scrollable {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      max-width: 100%;
      flex-grow: 1;
    }
  }

  .versionNr {
    position: relative;
    bottom: 0;

    @include media-breakpoint-up(md) {
      top: 20px;
      left: -30px;
    }
  }

  .mainPage.external {
    background: $background-url;
    background-size: cover;
    background-position: right;
    flex-grow: 1;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .scrollable {
      max-width: 480px;
      flex-grow: 0;
    }
  }
</style>
