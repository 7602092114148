<template>
  <div>
    <component
        v-if="notEmpty(testJSON)"
        :is="testJSON.type"
        :key="testJSON.key"
        :obj="testJSON"
    ></component>
  </div>
</template>

<script>

export default {
  data () {
    return {
      testJSON: {
        'key': 'Root',
        'type': 'LhRoot',
        'actions': [],
        'attributes':
            {
              'version':
                  '0.0.1',
              'pageTitle': 'About dev',
              'backgroundUrl': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse.svg'
            }
        ,
        'styles':
            [], 'children':
            [{
              'key': 'Root_Sidebar',
              'type': 'LhSidebar',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [{
                'key': 'root-menu-logo-client',
                'type': 'LhImage',
                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                'attributes': {
                  'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                  'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                  'class': 'client-logo'
                },
                'styles': { 'cursor': 'pointer' },
                'children': []
              },
                {
                'key': 'root-menu-scroll-content',
                'type': 'LhSidebarScrollContent',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-nav-list',
                  'type': 'LhNavList',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-nav-list-item-1',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_INDEX', 'action': '/', 'icon': 'home' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-2',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CLIENTS', 'action': '/clients', 'icon': 'file-alt' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-3',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PROJECTS', 'action': '/projects', 'icon': 'chart-pie' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-4',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PAGES', 'action': '/pages', 'icon': 'users' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-5',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CONFIG', 'action': '/users', 'icon': 'cog' },
                    'styles': [],
                    'children': [{
                      'key': 'root-menu-nav-sub-list-5',
                      'type': 'LhNavList',
                      'actions': [],
                      'attributes': [],
                      'styles': [],
                      'children': [{
                        'key': 'root-menu-nav-sub-list-5-item-1',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users' },
                        'styles': [],
                        'children': []
                      }, {
                        'key': 'root-menu-nav-sub-list-5-item-2',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_BLOCKS', 'action': '/pageblocks' },
                        'styles': [],
                        'children': []
                      }]
                    }]
                  }]
                }]
              },
                {
                'key': 'root-menu-logo-group',
                'type': 'LhDiv',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-logo-lighthouse',
                  'type': 'LhImage',
                  'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                  'attributes': {
                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                    'class': 'lighthouse-logo'
                  },
                  'styles': { 'cursor': 'pointer' },
                  'children': []
                }]
              }, {
                'key': 'root-menu-button-group',
                'type': 'LhButtonGroup',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-col-1',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-user-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                    'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                    'styles': [],
                    'children': []
                  }]
                }, {
                  'key': 'root-menu-col-2',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [
                      {
                    'key': 'root-menu-logout-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                    'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                    'styles': [],
                    'children': []
                  }
                  ]
                },
                  {
                    'key': 'root-menu-col-3',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'toggle-translations-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: 'translations-toggle-view',
                            type: 'emit',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'toggle-translations-button',
                          icon: 'globe',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  },
                  {
                    'key': 'root-menu-col-4',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'translations-popup-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: '',
                            type: '',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'translations-popup-button',
                          icon: 'file-alt',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  }
                  ]
              }]
            },
              {
                "key":"main-admin-dashboard",
                "type":"LhMainPage",
                "actions":[

                ],
                "attributes":[

                ],
                "styles":[

                ],
                "children":[
                  {
                    "key": "general-multifactor-popup-form",
                    "type": "LhForm",
                    "actions": [
                      {
                        "url": "\/multifactor\/verify\/f587d6aeeda8169d944f30d78f275f7de1c4a14527c85e86d64c999d6f6e51b29b5f700cc70f7945aaafef9ab20124df56b7c6ebb63d154cf8d35c086572ac6d",
                        "type": "update",
                        "method": "POST",
                        "event": "submit"
                      }
                    ],
                    "attributes": {
                      "id": "general-multifactor-popup-form",
                      "closeOnSave": "true",
                      "ignoreChanges": true
                    },
                    "styles": [],
                    "children": [
                      {
                        "key": "general-multifactor-component-group",
                        "type": "LhMultiFactor",
                        "actions": [],
                        "attributes": {
                          "success": true,
                          "multifactor": true,
                          "message": "GENERAL_MULTIFACTOR_TOKEN_SEND_PREFERRED",
                          "providers": {
                            "email": true,
                            "sms": false
                          },
                          "providerRoutes": {
                            "email": "https:\/\/api.jordy.dev.core.technieken.be\/multifactor\/renew\/email\/f587d6aeeda8169d944f30d78f275f7de1c4a14527c85e86d64c999d6f6e51b29b5f700cc70f7945aaafef9ab20124df56b7c6ebb63d154cf8d35c086572ac6d",
                            "sms": "https:\/\/api.jordy.dev.core.technieken.be\/multifactor\/renew\/sms\/f587d6aeeda8169d944f30d78f275f7de1c4a14527c85e86d64c999d6f6e51b29b5f700cc70f7945aaafef9ab20124df56b7c6ebb63d154cf8d35c086572ac6d"
                          }
                        },
                        "styles": [],
                        "children": []
                      }
                    ]
                  },
                  {
                    "key": "general-multifactor-submit-button",
                    "type": "LhButton",
                    "actions": [
                      {
                        "url": "\/multifactor\/verify\/f587d6aeeda8169d944f30d78f275f7de1c4a14527c85e86d64c999d6f6e51b29b5f700cc70f7945aaafef9ab20124df56b7c6ebb63d154cf8d35c086572ac6d",
                        "type": "submit",
                        "method": "POST",
                        "event": "click"
                      }
                    ],
                    "attributes": {
                      "content": "GENERAL_SUBMIT",
                      "form": "general-multifactor-popup-form",
                      "type": "submit",
                      "closeOnSave": true
                    },
                    "styles": [],
                    "children": []
                  }
                ]
              }
          ]
      },
    }
  }
}
</script>

<style scoped lang="scss">
</style>
